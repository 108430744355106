import axios from 'axios';
import { UploaderFileListItem } from 'vant';

import { apiDelOSSFile, apiGetAliSigned } from '@/api/oss';

// 全是 vant 定义的，我只是把要用到的提取出来
export interface MyUploadFile extends UploaderFileListItem {
  url: string;
  file?: File;
  status: '' | 'uploading' | 'done' | 'failed';
}

export const ImgURLtoUploadItem = (url: string): MyUploadFile => ({
  url,
  status: 'done',
  isImage: true,
});

/**
 * 上传至于 Ali OSS
 */
export const uploadPhoto = async (
  photoList: Array<MyUploadFile>
): Promise<Array<string>> => {
  const uploadFiles = photoList.map(async photo => {
    if (photo.status === 'done') return photo;

    const { file } = photo;
    if (!file) throw new Error('文件异常');

    photo.status = 'uploading';
    try {
      const url = await uploadOSS(file);
      if (url) {
        photo.status = 'done';
        photo.url = url;
        return photo;
      }
    } catch (error) {
      photo.status = 'failed';

      throw error;
    }
  });

  // allSettled 本身接受 promise ，所以async func 不行，得是 async func ()
  const results = await Promise.allSettled(uploadFiles);

  return results
    .map(promiseResult => {
      const { status } = promiseResult;
      if (status === 'fulfilled') {
        if (promiseResult.value) return promiseResult.value.url;
      }

      return '';
    })
    .filter(url => !!url); // 现在直接 过滤掉失败的 部分
};

const uploadOSS = async (file: File) => {
  // 获取上传 OSS 所必须的数据
  const res = await apiGetAliSigned();

  // expire
  const { policy, signature, accessid, host, dir } = res.data;

  /**
   *  a.png -> .png
   */
  const ext = (fileName: string) => {
    const index = fileName.lastIndexOf('.');

    return fileName.substring(index);
  };

  const fileName = dir + ext(file.name);

  const formData = new FormData();
  formData.append('key', fileName);
  formData.append('name', file.name);
  formData.append('policy', policy);
  formData.append('OSSAccessKeyId', accessid);
  formData.append('success_action_status', '200');
  formData.append('callback', '');
  formData.append('signature', signature);
  formData.append('file', file);

  // 上传 Aliyun 服务器
  const upOssRes = await axios({
    url: host,
    method: 'post',
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  if (upOssRes.status === 200) {
    return fileName;
  }

  throw new Error('' + upOssRes.status);
};

/**
 * 删除 OSS 图片
 */
export const delAliOSSPhoto = (photoPaths: Array<string>) => {
  return apiDelOSSFile(photoPaths);
};
