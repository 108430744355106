import { apiGetTagList } from '@/api/tag';

import { Module, Store } from 'vuex';

import { ITag } from '@/entity/life';
interface IState {
  tagList: Array<ITag>;
}

export const tag: Module<IState, any> = {
  namespaced: true,
  state: {
    tagList: [],
  },
  getters: {
    tagList(state) {
      return state.tagList;
    },
  },
  mutations: {
    updateTagList(state, payload: Array<ITag>) {
      state.tagList = payload;
    },
  },
  actions: {
    async loadUserTagList({ commit }) {
      const { success, data } = await apiGetTagList();

      if (success) commit('updateTagList', data);
    },
  },
};

export const updateTagList = (store: Store<any>) =>
  store.dispatch('tag/loadUserTagList');
