import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import {
  Button,
  Tabbar,
  TabbarItem,
  Popup,
  Field,
  CellGroup,
  Tab,
  Tabs,
  Dialog,
  Divider,
  Image as VanImage,
  ImagePreview,
  PullRefresh,
  List,
  Grid,
  GridItem,
  Uploader,
  NavBar,
  Collapse,
  CollapseItem,
} from 'vant';
import 'vant/es/notify/style';
import 'vant/es/dialog/style';
import 'vant/es/image-preview/style';
import 'vant/es/toast/style';

createApp(App)
  .use(store)
  .use(router)
  .use(Button)
  .use(Tabbar) // 底部导航栏
  .use(TabbarItem) // 底部导航栏
  .use(Popup) // 弹出框
  .use(Field) // 输入框
  .use(CellGroup) // 输入框
  .use(Tab) // 顶部切换
  .use(Tabs) // 顶部切换
  .use(Dialog)
  .use(Divider) // 分割线
  .use(VanImage)
  .use(ImagePreview) // 图片预览
  .use(PullRefresh) // 下拉刷新
  .use(List) // ListView
  .use(Grid) // 宫格
  .use(GridItem) // 宫格
  .use(Uploader) // 图片上传 📷
  .use(NavBar) // 顶部 导航栏
  .use(Collapse) // 折叠
  .use(CollapseItem)
  .mount('#app');
