import { apiLogin, apiTokenVerify } from '@/api/auth';
import { saveToken } from '@/api/tool/Token';
import { Notify } from 'vant';
import { Module } from 'vuex';

interface IState {
  token: string;
  status: '未登陆' | '登陆中' | '登陆成功' | '登陆失败';
}

export const login: Module<IState, any> = {
  namespaced: true,
  state: {
    token: '',
    status: '未登陆',
  },

  getters: {
    getLoginToken: state => state.token,
    isLogin: state => state.status === '登陆成功',
  },
  mutations: {
    setLoginToken(state, payload: string) {
      state.token = payload;
      saveToken(payload);
    },
  },
  actions: {
    async login(
      { commit, state, dispatch },
      payload: { phone: string; pwd: string }
    ) {
      state.status = '登陆中';
      try {
        const res = await apiLogin(payload);

        if (res.success) {
          commit('setLoginToken', res.data);
          Notify({ type: 'success', message: '登陆成功' });
          state.status = '登陆成功';
          dispatch('updateUserInfo');
        } else {
          throw new Error(res.message || '登陆失败');
        }
      } catch (error: any) {
        state.status = '登陆失败';
        Notify({ type: 'danger', message: error?.message || '' });
      }
    },

    async verifyToken({ commit, state, dispatch }) {
      state.status = '登陆中';
      try {
        const res = await apiTokenVerify();

        if (res.success) {
          commit('setLoginToken', res.data);
          state.status = '登陆成功';
          dispatch('updateUserInfo');
        }
      } catch (error) {
        state.status = '登陆失败';
      }
    },

    async updateUserInfo({ dispatch }) {
      dispatch('user/updateUserBasicInfo', undefined, { root: true });
      dispatch('user/updateUserSetting', undefined, { root: true });
    },
  },
};
