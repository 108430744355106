import {
  getAmapIpLocation,
  IPoiAroundItem,
  lonLatToAddress,
  lonLatToLocation,
  rectangleToLonLat,
} from '@/api/amap';
import { getLocation } from '@/utils/NativeLocation';

interface Param {
  lon: string;
  lat: string;
}

export const locationGetLonLat = async () => {
  const navigatorGeoResult = await getLocation();

  if (navigatorGeoResult.flag && navigatorGeoResult.data) {
    const { lat, lon } = navigatorGeoResult.data;

    if (lon && lat) {
      return {
        lon: lon + '',
        lat: lat + '',
      };
    }
  }

  const apiLocationRes: any = await getAmapIpLocation();
  console.log('高德定位', apiLocationRes);
  if (apiLocationRes.status == 1) {
    const { rectangle } = apiLocationRes;
    const [data1] = rectangleToLonLat(rectangle);
    if (data1) {
      const { lat, lon } = data1;
      if (lon && lat) {
        return { lon, lat };
      }
    }
  }

  return null;
};

export const getAMapLocationInfo = async ({
  lon,
  lat,
}: Param): Promise<IPoiAroundItem | null> => {
  if (!lat || !lon) return null;

  // const poitype = '餐饮服务|购物服务|生活服务|体育休闲服务|医疗保健服务|住宿服务|风景名胜|商务住宅|政府机构及社会团体|科教文化服务|交通设施服务|金融保险服务';

  const res = await lonLatToAddress({
    location: lonLatToLocation(lon, lat),
    poitype: '',
    extensions: 'all',
  });

  if (res.status !== '1') return null;
  const { regeocode = {} } = res as any;
  const { pois, addressComponent } = regeocode;
  if (!Array.isArray(pois) || !addressComponent) return null;

  const [_1st] = pois;
  const { id, name, location, distance, type, address } = _1st;
  const { province, adcode, district, citycode, city } = addressComponent;

  // 别问 ，问就是 高德 API 问题
  const cityname = city && typeof city === 'string' ? city : province;

  return {
    id,

    adcode,
    location,

    name,
    pname: province,
    cityname,
    adname: district,
    address,

    citycode,
    distance,
    pcode: '',
    type,
    typecode: '',
  };
};
