import { apiGetUserBasicInfo, apiGetSetting } from '@/api/user';
import { routePathUrl } from '@/router';
import { Notify } from 'vant';
import { Module } from 'vuex';

interface IBasicInfo {
  id: string;
  name: string;
  phone: string;
  img: string;
  gender: '男' | '女' | '';
}

interface ISetting {
  life: {
    autoPositioning: boolean;
  };
  presentationMode: boolean;
}

interface IState extends IBasicInfo {
  setting: ISetting;
}

export const user: Module<IState, any> = {
  namespaced: true,
  state: {
    id: '',
    name: '',
    phone: '',
    gender: '',

    // https://consumer.huawei.com/etc/designs/huawei-cbg-site/clientlib-campaign-v4/common-v4/images/logo.svg
    // https://img.olympicchannel.com/images/image/private/f_auto/w_600/primary/lqdmzt3zbmjoqlkyxeay
    img: '',
    setting: {
      life: {
        autoPositioning: false,
      },
      presentationMode: false,
    },
  },

  getters: {
    userName: state => {
      if (state.name) return state.name;
      const { id } = state;

      const nameTag = 'woji'; // '窝叽用户'
      if (id && id.length) {
        return nameTag + '_' + id;
      }

      return nameTag;
    },
    userPhone: state => {
      const { phone } = state;
      if (!phone || !phone.length) return '*** **** ****';

      let a = '',
        b = '',
        c = '';

      if (phone.length > 3) a = phone.substring(0, 3);

      const cLen = Math.floor((phone.length - 3) / 2);

      if (cLen) c = phone.slice(-cLen);

      const bLen = phone.length - 3 - cLen;

      for (let i = 0; i < bLen; i++) {
        b += '*';
      }

      return `${a} ${b} ${c}`;
    },
    headImg: state => state.img || routePathUrl('/dimg/head.svg'),
    presentationMode: state => state?.setting?.presentationMode || false,
    autoPositioning: state => {
      return state?.setting?.life?.autoPositioning || false;
    },
  },
  mutations: {
    setUserBasicInfo(state, payload: IBasicInfo) {
      const { id, name, phone, img } = payload;
      state.id = id ?? state.id;
      state.name = name ?? state.name;
      state.phone = phone ?? state.phone;
      state.img = img ?? state.img;
    },

    setUserSetting(state, payload: ISetting) {
      if (payload) {
        console.log('todo', '设置是否 需要 每一条每一条 的进行检查？');
        state.setting = payload;
      }
    },
  },
  actions: {
    async updateUserBasicInfo({ commit }) {
      const res = await apiGetUserBasicInfo();
      if (res.success) {
        commit('setUserBasicInfo', res.data);
      }
    },
    async updateUserSetting({ commit }) {
      const res = await apiGetSetting();
      if (res.success && res.data) {
        const { setting } = res.data;
        console.log('setting', setting);
        commit('setUserSetting', setting);
      }
    },
  },
};
