import { createStore } from 'vuex';
import { login } from './modules/login';
import { user } from './modules/user';
import { tag } from './modules/tag';

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: { login, tag, user },
});
