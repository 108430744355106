import { post, get } from './tool/HttpClient';

export const apiAddLifeMoment = (data: {
  text?: string;
  photo?: Array<string>;
  video?: Array<string>;
  tag?: Array<string>;
  location?: { name: string; lat: string; lon: string; address: string[] };
}) => post('/life/add', data);

interface getLifeMomentParams {
  page: number;
  pageSize: number;
  tag?: Array<string> | string;
  startDate?: number;
  endDate?: number;
}
export const apiGetLifeMoment = (data: getLifeMomentParams) =>
  get('/life/u', data);

export const apiEditLifeMoment = (data: {
  id: string;
  text?: string;
  photo?: Array<string>;
  video?: Array<string>;
  tag?: Array<string>;
  location?: {
    name: string;
    lat: string;
    lon: string;
    adcode?: string[];
    address?: string[];
  };
}) => post('/life/edit', data);

export const apiGetLifeLocation = () => get('/life/location');
