import { ITag } from '@/entity/life';
import { post, get } from './tool/HttpClient';

export const apiAddTag = (data: {
  name: string;
  color: { bg: string; border: string };
}) => post('/tag/add', data);

export const apiGetTagList = (page?: number, pageSize?: number) =>
  get('/tag/', { page, pageSize });

export const apiEditTag = (tag: ITag) => post('/tag/edit', tag);
