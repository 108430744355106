import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import HomeView from '@/views/Home/index.vue';
import HomeMemoryView from '@/views/Home/Memory/index.vue';
import HomeSquareView from '@/views/Home/Square/index.vue';
import HomeMyView from '@/views/Home/My/index.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/home/memory',
  },
  {
    path: '/home',
    redirect: '/home/memory',
    name: 'home',
    component: HomeView,
    meta: { keepAlive: true },
    children: [
      {
        path: 'memory',
        component: HomeMemoryView,
      },
      {
        path: 'square',
        component: HomeSquareView,
      },
      {
        path: 'my',
        component: HomeMyView,
      },
    ],
  },
  {
    path: '/tag',
    name: 'tag',
    component: () => import('../views/NavBarPage/Tag/tag.vue'),
  },
  {
    path: '/setting',
    name: 'setting',
    component: () => import('../views/NavBarPage/Setting/setting.vue'),
  },
  /////////////
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/TodoView.vue'),
  },
];

const routePath = '/v/';

export const routePathUrl = (url: string): string => {
  if (!url) return routePath;

  if (url.startsWith('/')) {
    return routePath + url.substring(1);
  } else {
    return routePath + url;
  }
};

const router = createRouter({
  // process.env.BASE_URL
  history: createWebHistory(routePath),
  routes,
});

export default router;
