export const saveToken = (token: string) => {
  localStorage.setItem('jwt', token);
};

/**
 * Authorization  Bearer
 */
export const getToken = () => localStorage.getItem('jwt') || '';

export const isLogin = () => Boolean(getToken());
