type PromiseReject = (reason?: any) => void;
type PromiseResolve<T = any> = (value?: T | PromiseLike<T>) => void;

type INavigatorGeoResult = ILocationOkResult | ILocationFaildResult;

interface ILocationOkResult {
  flag: boolean;
  data: {
    lat: number;
    lon: number;
    /**
     coords.latitude	十进制数的纬度
     coords.longitude	十进制数的经度
     coords.accuracy	位置精度
     coords.altitude	海拔，海平面以上以米计
     coords.altitudeAccuracy	位置的海拔精度
     coords.heading	方向，从正北开始以度计
     coords.speed	速度，以米/每秒计
     */
    coords?: GeolocationCoordinates;
  } | null;
  msg?: string;
}

interface ILocationFaildResult {
  flag: false;
  code: number;
  /**
   * 这个 message 是英文，API提供的。
   */
  message: string;
  /**
   * 这个 msg 是自己处理的，中文
   */
  msg: string;
}

interface _Params {
  resolve: PromiseResolve<any>;
  reject: PromiseReject;
}

/**
 * 解决提示
 *
 */
const SolutionTips = {
  mac: '系统偏好设置 > 安全性与隐私 > 定位服务',
  h5: 'H5 需要 https 才能获取到定位信息',
};

function getLocationSuccess({ resolve }: _Params) {
  return (position: GeolocationPosition) => {
    // https://developer.mozilla.org/zh-CN/docs/Web/API/GeolocationPosition

    const result: ILocationOkResult = {
      flag: false,
      data: null,
      msg: '获取定位失败：无有效数据，请稍后重试。',
    };

    if (position?.coords) {
      const { latitude, longitude } = position?.coords;
      result.flag = true;
      result.msg = '获取定位成功';
      result.data = {
        lat: latitude,
        lon: longitude,
        coords: position.coords,
      };
    }

    resolve(result);
  };
}

function getLocationError({ resolve }: _Params) {
  return (error: GeolocationPositionError) => {
    // https://developer.mozilla.org/zh-CN/docs/Web/API/GeolocationPositionError
    console.log('获取定位失败', error.code, error.message);

    let msg = '';
    if (error.code) {
      switch (error.code) {
        case GeolocationPositionError.PERMISSION_DENIED:
          msg = '缺少定位权限';
          break;
        case GeolocationPositionError.POSITION_UNAVAILABLE:
          msg = '手机内部无法获取定位信息';
          break;
        case GeolocationPositionError.TIMEOUT:
          msg = '获取定位超时';
          break;
        default:
          msg = '未知错误：' + error.code;
      }
    }

    const result: ILocationFaildResult = {
      flag: false,
      message: error.message,
      code: error.code,
      msg,
    };

    resolve(result);
  };
}

export const getLocation = (
  timeout: number = 3000
): Promise<INavigatorGeoResult> => {
  const options = {
    /**
     * A request from the application to receive the best possible location results.
     *
     * The intended purpose of this attribute is to allow applications to inform the implementation that they do not require high accuracy geolocation fixes so the implementation can avoid using geolocation providers that consume a significant amount of power
     */
    enableHighAccuracy: true,
    timeout: timeout ?? 3000,
    /**
     * Indicates that the application is willing to accept a cached position whose age is no greater than the specified time
     */
    maximumAge: 0,
  };
  return new Promise((resolve, reject) => {
    // https://developer.mozilla.org/zh-CN/docs/Web/API/Geolocation/getCurrentPosition
    navigator.geolocation.getCurrentPosition(
      getLocationSuccess({ resolve, reject }),
      getLocationError({ resolve, reject }),
      options
    );
  });
};

// // // // // // // // // // // //

// 持续 获取 坐标，todo
