import { post, get } from './tool/HttpClient';

export const apiSendSMS = (type: 'regist' | 'login', phone: string) =>
  post('/sms/', { phone, type });

export const apiRegist = (data: { pwd: string; phone: string; code: string }) =>
  post('/auth/regist', { ...data, smsCode: data.code });

export const apiLogin = (data: { pwd: string; phone: string }) =>
  post('/auth/login', data);

export const apiTokenVerify = () => get('/auth/verify');
