import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

const amapAxios = axios.create();
amapAxios.interceptors.response.use((res: AxiosResponse) => res.data);

interface IAmapResponse {
  status: '1' | string;
}

const get = <T = IAmapResponse>(
  url: string,
  data?: any,
  options?: AxiosRequestConfig
): Promise<T> =>
  amapAxios.get<T>(url, {
    params: data,
    ...options,
  }) as any;

const amapKey = '8d189f0269258d171d9fb2de442360c3';

interface BasicAmapParams {
  sig?: any; // 数字签名 https://lbs.amap.com/faq/account/key/72
  output?: 'JSON' | 'XML'; // 返回数据格式类型 可选输入内容包括：JSON，XML。设置 JSON 返回结果数据将会以JSON结构构成；如果设置 XML 返回结果数据将以 XML 结构构成。
  callback?: any; // 回调函数 ,值是用户定义的函数名称，此参数只在 output 参数设置为 JSON 时有效。
}

interface IpParams extends BasicAmapParams {
  ip?: string;
}

export const lonLatToLocation = (lon: number | string, lat: number | string) =>
  `${lon},${lat}`;

export const locationToLonLat = (location: string) => {
  const [lon, lat] = location.split(',');

  return { lon, lat };
};
export const rectangleToLonLat = (rectangle: string) => {
  const locations = rectangle.split(';');
  if (!Array.isArray(locations)) return [];

  return locations.map(locationToLonLat);
};

////////////////////////////////////////////////////////////////
// https://lbs.amap.com/api/webservice/guide/api/ipconfig
export const getAmapIpLocation = (p: IpParams = {}) =>
  get('https://restapi.amap.com/v3/ip', {
    key: amapKey,
    ...p,
  });

////////////////////////////////////////////////////////////////

interface RegetParams extends BasicAmapParams {
  /**
   * 经度在前，纬度在后，经纬度间以“,”分割，经纬度小数点后不要超过 6 位。
   */
  location: string;
  poitype?: string; // 返回附近POI类型 https://a.amap.com/lbs/static/amap_3dmap_lite/amap_poicode.zip
  radius?: number; // radius取值范围在0~3000，默认是1000。单位：米
  extensions?: 'base' | 'all'; // 返回结果控制
  roadlevel?: 0 | 1; // 道路等级。extensions 参数为 all 时才生效 。当roadlevel=0时，显示所有道路 ，当roadlevel=1时，过滤非主干道路
}
export const lonLatToAddress = (p: RegetParams) =>
  get<IAmapResponse>('https://restapi.amap.com/v3/geocode/regeo', {
    key: amapKey,
    ...p,
  });

////////////////////////////////////////////////////////////////

interface IParamPoi2Around extends BasicAmapParams {
  keywords?: string;
  location: string;

  /**
   * 搜索半径，默认 5000
   *
   * 取值范围:0-50000
   */
  radius?: number;

  /**
   * 按距离排序：distance（默认）
   *
   * 综合排序：weight
   */
  sortrule?: 'distance' | 'weight';

  /**
   * 搜索区划，默认全国
   *
   * citycode或adcode
   */
  region?: string;

  /**
   * 只返回指定城市数据
   */
  city_limit?: boolean;
}

export interface IPoiAroundItem {
  id: string; // 'B0FFLF7YWJ';

  adcode: string; // '500116';
  location: string; //'106.279462,29.369966';

  name: string; //'双福垃圾中转站';

  pname: string; //'重庆市'; 省！
  cityname: string; //'重庆市';
  adname: string; // '江津区';
  address: string; // '团结大道与福城南干道交叉口东240米';

  citycode?: string; //'023';
  distance?: string; //'372';
  pcode?: string; // '500000';
  type?: string; // '生活服务;生活服务场所;生活服务场所';
  typecode?: string; // '070000';
}

interface IResponsePoi2Around extends IAmapResponse {
  count: string;
  pois: IPoiAroundItem[];
}

// https://lbs.amap.com/api/webservice/guide/api/newpoisearch
export const getPoi2Around = (p: IParamPoi2Around) =>
  get<IResponsePoi2Around>('https://restapi.amap.com/v5/place/around', {
    key: amapKey,
    ...p,
  });
