export const getColorLine_StyleValue = (date?: Date) => {
  const colors = [
    'linear-gradient( 135deg, #FF6FD8 10%, #3813C2 100%)', // 紫
    //
    'linear-gradient( 135deg, #FE91AC 10%, #FF0000 100%)', // 红
    'linear-gradient( 135deg, #F89743 10%, #ED722E 100%)', // 橙
    'linear-gradient( 135deg, #FDEB71 10%, #F8D800 100%)', // 黄
    'linear-gradient( 135deg, #81FBB8 10%, #28C76F 100%)', // 绿
    'linear-gradient( 135deg, #ABDCFF 10%, #0396FF 100%)', // 浅蓝
    'linear-gradient( 135deg, #0396FF 10%, #FF6FD8 100%)', // 深蓝
  ];

  const defaultColor = 'linear-gradient( 135deg, #fd8019 1%, #E80505 100%)';

  if (!date) {
    return defaultColor;
  }

  // 0-6 , 0 表示星期天
  const index = date.getDay();

  if (index > 6) return defaultColor;

  return colors[index];
};
