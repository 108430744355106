import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "setting-item-icon-root"
};
const _hoisted_2 = {
  class: "icon"
};
export function render(_ctx, _cache) {
  const _component_van_icon = _resolveComponent("van-icon");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_renderSlot(_ctx.$slots, "icon", {}, () => [_createVNode(_component_van_icon, {
    name: "cross"
  })])]), _createElementVNode("div", null, [_renderSlot(_ctx.$slots, "default")])]);
}